<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Data Pengajuan Absensi
              </h5>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="NIP" rules="required">
                <b-form-group label="NIP" label-for="employee_nip" :state="errors.length > 0 ? false : null">
                  <v-select id="employee_nip" v-model="dataParent.employee_nip"
                    :reduce="(employee_nip) => employee_nip.nip" placeholder="Pilih Pegawai"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPegawai" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Dari Tanggal" label-for="start_date">
                <validation-provider #default="{ errors }" name="Dari Tanggal" rules="required">
                  <b-form-input id="start_date" v-model="dataParent.start_date" type="date"
                    :state="errors.length > 0 ? false : null" placeholder="Dari Tanggal" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Hingga Tanggal" label-for="end_date">
                <validation-provider #default="{ errors }" name="Hingga Tanggal" rules="required">
                  <b-form-input id="end_date" v-model="dataParent.end_date" type="date"
                    :state="errors.length > 0 ? false : null" placeholder="Hingga Tanggal" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Dari Jam" label-for="time_in">
                <validation-provider #default="{ errors }" name="Dari Jam" rules="required">
                  <b-form-input id="time_in" v-model="dataParent.time_in" type="time"
                    :state="errors.length > 0 ? false : null" placeholder="07:00" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Hingga Jam" label-for="time_out">
                <validation-provider #default="{ errors }" name="Hingga Jam" rules="required">
                  <b-form-input id="time_out" v-model="dataParent.time_out" type="time"
                    :state="errors.length > 0 ? false : null" placeholder="17:00" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider #default="{ errors }" name="type" rules="required">
                <b-form-group label="Type Absent" label-for="type" :state="errors.length > 0 ? false : null">
                  <v-select id="type" v-model="dataParent.type"
                    :reduce="(type) => type.real_id" placeholder="Pilih Type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listAbsentType" label="absent_type_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="approve" rules="required">
                <b-form-group label="Status" label-for="approve" :state="errors.length > 0 ? false : null">
                  <v-select id="approve" v-model="dataParent.approve"
                    :reduce="(approve) => approve.real_id" placeholder="Pilih Status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listApprovalStatus" label="approval_status" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- submit and reset -->
          </b-row>
          <b-row class="text-right">
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    vSelect,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      listPegawai: [],
      listAbsentType: [],
      listApprovalStatus: [],
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataParent: {
        employee_nip: '',
        start_date: '',
        end_date: '',
        time_in: '',
        time_out: '',
        approve: '',
        type:'',
        
      },
      dataClasss: [{ value: '4nvoeygeoxw3zpra', text: 'VII' }],
    }
  },
  computed: {

  },
  created() {
    this.getpegawai(),
    this.absenttype(),
    this.approvalstatus()
  },
  methods: {
    async getpegawai() {
      try {
        const response = await this.$http.get('/employees')
        this.listPegawai = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async absenttype() {
      try {
        const response = await this.$http.get('/absenttypes')
        this.listAbsentType = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async approvalstatus() {
      try {
        const response = await this.$http.get('/approvalabsentstatuses')
        this.listApprovalStatus = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/employeeabsents', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Pengajuan Absensi Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'pengajuan-absensi' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Pengajuan Absensi Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
